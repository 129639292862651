$.extend($.validator.messages, {
  required: "هذا الحقل مطلوب",
  remote: "برجاء إصلاح هذا الحقل",
  email: "أدخل البريد الإلكتروني بطريقة صحيحة",
  url: "أدخل الرابط بطريقة صحيحة",
  slug: "ابدأ بحرف. حروف وأرقام و ـ",
  date: "أدخل الوقت بطريقة صحيحة",
  time24: "أدخل الوقت بطريقة صحيحة",
  dateISO: "أدخل الوقت بطريقة صحيحة (ISO).",
  number: "أدخل الرقم بطريقة صحيحة",
  digits: "أدخل أرقام فقط",
  creditcard: "أدخل رقم البطاقة بطريقة صحيحة",
  equalTo: "القيمة غير صالحة",
  notEqual: "القيمة غير صالحة",
  accept: "أدخل القيمة بامتداد مقبول",
  maxlength: $.validator.format("ادخل بحد أقصى{0} حرف."),
  minlength: $.validator.format("أدخل على الأقل {0} حرف."),
  rangelength: $.validator.format("أدخل عدد أحرف بين {0} و {1}"),
  range: $.validator.format("أدخل قيمة بين {0} and {1}"),
  max: $.validator.format("أدخل قيمة أقل من أو تساوي {0}."),
  min: $.validator.format("أدخل قيمة أكبر من أو تساوي {0}."),
  lettersdigitsonly: "أدخل حروف وأرقام فقط",
  username: "أدخل حروف ، أرقام، أو (-_ .) فقط",
  password: "أدخل حروف ، أرقام، أو (-!@#$%^&*+=) فقط",
  lettersonly: "أدخل حروف فقط",
  ip_address: "عنوان الآي بي غير صالح",
  noMultiSpaces: "يوجد مسافات متكررة",
  number2decimal: "القيمة غير صالحة",
  grouped2decimal: "القيمة غير صالحة",
  device_serial: "القيمة غير صالحة",
  device_pnc: "القيمة غير صالحة",
  tel: "الرقم غير صالح",
  sa_mob: "الرقم غير صالح",
  eg_mob: "الرقم غير صالح",
  date10dashed: "التاريخ غير صالح",
  dateGreaterThan: "التاريخ يجب أن يكون أكبر",
  dateLessThan: "التاريخ يجب أن يكون أقل",
  ar_only: "أحرف عربية فقط",
  ar: "أحرف عربية وأرقام فقط",
});
